/******************************************************************************************/
/* HTML5 Reset
/******************************************************************************************/

body, div,
h1, h2, h3, h4, h5, h6,
p, blockquote, pre, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, th, td,
article, aside, figure, footer, header, hgroup, menu, nav, section {
	border:0;
	margin:0;
	padding:0;
}

/******************************************************************************************/
/* Consistency Fixes
/******************************************************************************************/

article, aside, details, figcaption, figure, main,
footer, header, hgroup, nav, section, audio, canvas, video {
	display:block;
}
html {
	background:#fff;
	-ms-text-size-adjust:100%;
	-webkit-text-size-adjust:100%;
}
body {
	min-height:100%;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
}
sub, sup {
	font-size:75%;
	line-height:0;
	position:relative;
	vertical-align:baseline;
}
sup {top:-0.5em;}
sub {bottom: -0.25em;}
pre {
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}
b, strong {font-weight: bold;}
abbr[title] {border-bottom: 1px dotted;}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
a img, img {
	-ms-interpolation-mode: bicubic;
	border: 0;
}
::-webkit-input-placeholder {
	color:@grey;
	font-weight:400;
}
:-moz-placeholder {
	color:@grey;
	font-weight:400;
}
::-moz-placeholder {
	color:@grey;
	font-weight:400;
}
:-ms-input-placeholder {
	color:@grey;
	font-weight:400;
}
:focus::-webkit-input-placeholder {
	color:transparent;
}
:focus:-moz-placeholder {
	color:transparent;
}
:focus::-moz-placeholder {
	color:transparent;
}
:focus:-ms-input-placeholder {
	color:transparent;
}
input, textarea, button, select {
	margin: 0;
	font-size: 100%;
	line-height: normal;
	vertical-align: baseline;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
}
button:focus {
	outline:0;
}
input[type="checkbox"],
input[type="radio"] {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
		 -o-box-sizing: border-box;
		-ms-box-sizing: border-box;
			box-sizing: border-box;
}
input[type="search"] {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	-webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
textarea {
	overflow: auto;
}
embed,object,video {
	max-width: 100%;
}
img	{
	height:auto;
	max-width:100%;
}
select:-ms-expand {
    display:none;
}
select::-ms-expand {
    display:none;
}
::selection,
::-moz-selection,
img::selection,
img::-moz-selection {
	color:#fff;
	text-shadow:none;
	background-color:#222;
}
