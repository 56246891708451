/******************************************************************************************/
/* Typography Styles
/******************************************************************************************/

html {
    position:relative;
    min-height:100%;
    background-color:#f6f6f6;
}
body {
    position:relative;
    color:@grey;
    line-height:1;
    font-size:16px;
    font-weight:400;
    font-family:@bodyfont;
    background-color:#f6f6f6;
    font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    -webkit-font-smoothing:antialiased;
}
h1, h2, h3, h4, h5, h6 {
    color:@orange;
    margin:24px 0 0;
    font-weight:400;
    line-height:1.4;
    font-family:@bodyfont;
}
h1 {
    margin:0;
    .font-size(30);
}
h2 {
    .font-size(28);
}
h3 {
    .font-size(16);
}
h4, h5, h6 {
    .font-size(22);
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    text-decoration:none;
}
.title-grey {
    color:@grey;
}
p {
    margin:24px 0;
    line-height:1.5;
}
a {
    color:@blue;
    text-decoration:underline;
}
a:focus {
    outline:0;
}
a:hover {
    text-decoration:none;
}
hr {
    border:0;
    margin:24px 0;
    overflow:hidden;
    border-top:1px solid @orange;
}
pre {
    margin:24px 0;
}
pre p {
    margin:10px 0;
}
dl {
    margin:24px 0;
    overflow:hidden;
}
dl dt,
dl dd {
    float:left;
    padding:10px 0;
}
dl dt {
    clear:left;
}
ul, ol {
    padding:0;
    list-style:none;
    margin:24px 0 0;
}
ol {
    counter-reset:item;
}
ol li:before {
    position:absolute;
    top:0;
    left:0;
    color:@grey;
    display:block;
    counter-increment:item;
    content:counter(item)'. ';
}
li {
    position:relative;
    line-height:1.4;
    margin:0 0 14px;
    padding:0 0 0 18px;
}
ul li:before {
    position:absolute;
    top:8px;
    left:0;
    content:'';
    .square(6px);
    display:block;
    overflow:hidden;
    .border-radius(6px);
    background-color:@orange;
}
ul ul {
    margin:14px 0 0;
}
ul ul li:before {
    position:absolute;
    top:12px;
    left:2px;
    .size(8px,2px);
    .border-radius(0);
    background-color:@grey;
}
blockquote {
    padding:20px;
    margin:32px 0;
    text-align:center;
    border-top:3px solid @orange;
    border-bottom:3px solid @orange;
}
blockquote p {
    margin:0;
    padding:0;
    color:@orange;
    .font-size(20);
    font-weight:300;
    font-style:italic;
}
blockquote cite {
    color:@grey;
    display:block;
    .font-size(18);
    margin:12px 0 0;
    font-weight:300;
}
figure {
    padding:20px;
    margin:32px 0;
    text-align:center;
    border:1px solid @orange;
}
figure img {
    .inline-block();
    vertical-align:middle;
}
img {
    display:block;
}
.aligncenter {
    .inline-block();
    text-align:center;
}

/******************************************************************************************/
/* Structure
/******************************************************************************************/

main, .header, .footer {
    background-color:#fff;
    position:relative;
    max-width:1024px;
    margin:0 auto;
}
section {
    position:relative;
}
.inner {
    position:relative;
    max-width:960px;
    margin:0 auto;
    @media only screen and (max-width:980px) {
        width:92%;
    }
}
.visible {
    display:block;
}
.lock .panel-hero-area {
    @media only screen and (max-width:980px) {
        visibility:hidden;
    }
}
.smaller {
    .font-size(10);
    @media only screen and (max-width: 720px) {
        .font-size(14);
    }
}
.larger {
    .font-size(16);
    @media only screen and (max-width: 720px) {
        .font-size(14);
    }
}
.border-orange {
    border:4px solid @orange;
}
.homepage-inner {
    position:relative;
    width:92%;
    max-width:820px;
    margin-left:auto;
    margin-right:auto;
}
.with-floats {
    position:relative;
    overflow:hidden;
    margin:24px 0 34px;
}
.with-floats .left {
    float:left;
}
.with-floats .right {
    float:right;
}
.with-floats .half {
    width:50%;
    @media only screen and (max-width: 720px) {
        width:auto;
    }
}

/******************************************************************************************/
/* Header
/******************************************************************************************/

.header {
    position:relative;
    height:206px;
    @media only screen and (max-width:980px) {
        height:auto;
    }
}
.header .inner {
    overflow:hidden;
    height:130px;
    @media only screen and (max-width:600px) {
        height:auto;
    }
}
.header .logo {
    float:left;
    display:block;
    margin:25px 0 0;
    overflow:hidden;
    .size(228px,79px);
    text-indent:-9999px;
    background:url('../images/logo.svg') 0 50% no-repeat;
    background-size:contain;
    @media only screen and (max-width:767px) {
        .size(202px,74px);
    }
    @media only screen and (max-width:600px) {
        position:absolute;
        top:25px;
        margin:0;
        left:0;
        float:none;
    }
}
.splash-header {
    height:130px;
    @media only screen and (max-width:640px) {
        height:160px;
    }
}
.splash-header .inner {
    height:130px;
    @media only screen and (max-width:640px) {
        height:160px;
    }
}
.splash-header .strapline {
    display:block;
    .font-size(11);
    margin:84px 0 0;
    text-align:right;
    color:@lightgrey;
    line-height:1.3em;
    @media only screen and (max-width:860px) {
        margin:64px 0 0;
    }
    @media only screen and (max-width:640px) {
        text-align:left;
        margin:110px 0 0;
    }
}
.open-menu-btn {
    position:absolute;
    top:46px;
    right:0;
    display:none;
    .square(36px);
    overflow:hidden;
    text-indent:-9999px;
    background:url('../images/menu-icon.svg') 50% no-repeat;
    background-size:contain;
    @media only screen and (max-width:980px) {
        display:block;
    }
}
.open-search-btn {
    position:absolute;
    top:50px;
    right:52px;
    display:none;
    .square(30px);
    overflow:hidden;
    text-indent:-9999px;
    background:url('../images/search-icon-alt.svg') 50% no-repeat;
    background-size:contain;
    @media only screen and (max-width:600px) {
        display:block;
    }
}
.header nav {
    background-color:@orange;
}
.header-navigation {
    position:relative;
    .unlisted();
    font-size:0;
    margin:0 auto;
    max-width:960px;
    @media only screen and (max-width:980px) {
        position:absolute;
        top:130px;
        right:0;
        left:0;
        margin:0;
        z-index:99;
        display:none;
        padding:10px 0;
        background-color:#fff;
        border-top: 4px solid @orange;
        border-bottom:4px solid @orange;
    }
}
.open-up {
    display:block;
}
.header-navigation li {
    position:static;
    margin:0;
    height:18px;
    padding:10px 0;
    .inline-block();
    line-height:18px;
    text-align:center;
    vertical-align:middle;
    @media only screen and (max-width:980px) {
        width:100%;
        height:auto;
        display:block;
        padding:10px 0;
    }
}
.header-navigation li:before {
    display:none;
}
.header-navigation a {
    position:relative;
    color:#fff;
    display:block;
    .font-size(14);
    padding:0 12px;
    font-weight:400;
    text-decoration:none;
    border-left:1px solid #fff;
    .transition(color 250ms linear);
    @media only screen and (max-width:980px) {
        padding:0;
        color:@orange;
    }
}
.header-navigation a:hover {
    text-decoration:underline;
}
.header-navigation li:first-child a {
    border:0;
    padding-left:0;
}
.header-navigation .current-menu-item a,
.header-navigation .current-menu-parent a,
.header-navigation .sub-menu .current_page_item a {
    font-weight:700;
}
.header-navigation .current-menu-parent a:after,
.header-navigation .current-menu-parent a:after {
    position:absolute;
    bottom:-10px;
    left:50%;
    width:0;
    height:0;
    content:'';
    margin:0 0 0 -5px;
    border-style:solid;
    border-width:0 5px 6px 5px;
    border-color:transparent transparent #fff transparent;
}
.header-navigation .sub-menu {
    position:absolute;
    left:0;
    top:38px;
    margin:0;
    z-index:2;
    height:38px;
    display:none;
    line-height:38px;
    background-color:#fff;
    @media only screen and (max-width:980px) {
        position:relative;
        top:auto;
        left:auto;
        height:auto;
        display:block;
        margin:6px 0 0;
    }
}
.header-navigation .current-menu-parent .sub-menu {
    display:block;
}
.header-navigation .sub-menu li {
    position:relative;
    @media only screen and (max-width:980px) {
        margin:12px 0 0;
        padding:0;
    }
}
.header-navigation .sub-menu li a {
    color:@grey;
    font-weight:400;
    border-color:@grey;
    @media only screen and (max-width:980px) {
        border:0;
    }
}
.header-navigation .sub-menu li.active a {
    font-weight:700;
}
.search-form {
    float:right;
    margin:45px 0 0;
    @media only screen and (max-width:980px) {
        margin:47px 53px 0 0;
    }
    @media only screen and (max-width:600px) {
        height:0;
        float:none;
        clear:both;
        overflow:hidden;
        margin:130px 0 0;
    }
}
.search-open {
    height:auto;
    margin:130px 0 20px;
}
.search-form form {
    position:relative;
}
.search-form label {
    display:none;
}
.search-form input[type=text] {
    border:0;
    width:100%;
    color:@grey;
    height:auto;
    outline:none;
    display:block;
    .font-size(14);
    min-width:240px;
    line-height:16px;
    font-style:italic;
    .appearance(none);
    .border-radius(0);
    background-color:#fff;
    vertical-align:middle;
    .box-sizing(border-box);
    border:1px solid @orange;
    padding:8px 46px 8px 16px;
    @media only screen and (max-width:600px) {
        padding-top:0;
        .font-size(16);
        padding-bottom:0;
        line-height:36px;
    }
}
.search-form input[type=submit] {
    position:absolute;
    top:7px;
    right:16px;
    border:0;
    margin:0;
    padding:0;
    .square(22px);
    overflow:hidden;
    text-indent:-9999px;
    background:url('../images/search-icon.svg') 50% no-repeat;
    background-size:contain;
    @media only screen and (max-width:600px) {
        top:9px;
    }
}
/******************************************************************************************/
/* HCP Overlay
/******************************************************************************************/
.professional .warning__modal {
  display:none;
}
.warning__modal {
  z-index: 1;
  position:absolute;
  width:100%;
  // display:none;
  height:100vh;
  z-index:1000;
  background: rgba(255,255,255,0.9);
  .checkboxes label:after {
      content:'';
      .square(22px);
      .inline-block();
      margin:-3px 0 0 12px;
      vertical-align:middle;
      background:url('../images/checkboxes.png') no-repeat;
      .image-2x('../images/checkboxes@2x.png', 22px, 88px);
  }
  .checkboxes input[type='checkbox']:checked + label:after {
      background-position:0 -44px;
  }
  .checkboxes input[type='checkbox'] {
    opacity:0;
    position:absolute;
    top:0;
    left:0;
  }
  .wrapper {
    padding:20px 0 15px 0;
  }
  h1 {
    text-transform:uppercase;
    .font-size(18);
    font-weight:400;
    padding-bottom:10px;
    border-bottom:1px solid white;
    color: #ffffff;
  }
  p, label {
    color: #ffffff;
    .font-size(14);
  }
  .form-actions {
    text-align:center;
    margin-top:15px;
    button {
      .font-size(16);
      text-transform:uppercase;
      padding: 10px 45px;
    }
  }
  .inner {
    padding:20px 20px 15px 20px;
    background:@orange;
    max-width: 820px;
    height:auto;
  }
}

/******************************************************************************************/
/* Footer
/******************************************************************************************/

.footer {
    position:relative;
}
.footer-safety-callout {
    position:relative;
    padding:10px 4%;
    margin:0 0 32px;
    text-align:center;
    background-color:@grey;
}
.splash-footer-safety-callout {
    margin:0;
}
.footer-safety-callout .callout-btn {
    margin:10px 0;
    padding:12px 32px;
    text-transform:uppercase;
    @media only screen and (max-width:720px) {
        display:block;
        padding:12px 0;
        .box-sizing(border-box);
    }
}
.footer-safety-callout .left-btn {
    margin:10px 60px 10px 0;
    @media only screen and (max-width:720px) {
        width:100%;
        margin:10px 0;
    }
}
.footer-safety-callout .right-btn {
    margin:10px 0 10px 60px;
    @media only screen and (max-width:720px) {
        width:100%;
        margin:10px 0;
    }
}
.footer-dashboard .inner {
    position:relative;
    padding:20px 0;
}
.footer-dashboard .inner:before {
    position:absolute;
    top:0;
    left:0;
    right:0;
    content:'';
    height:1px;
    display:block;
    background-color:@orange;
}
.footer-dashboard .inner:after {
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    content:'';
    height:4px;
    display:block;
    background-color:@orange;
}
.footer-dashboard h3 {
    color:@orange;
    margin:0 0 6px;
    .font-size(16);
    font-weight:700;
    padding:0 0 6px;
    border-bottom:1px solid @orange;
}
.footer-dashboard .right-align {
    text-align:right;
    padding-top:102px;
    @media only screen and (max-width:767px) {
        width:100%;
        padding:10px 0;
        text-align:left;
    }
}
.footer-dashboard .right-align p {
    margin:0;
    .font-size(12);
    @media only screen and (max-width:720px) {
        .font-size(14);
    }
}
.footer-navigation {
    margin:0;
    padding:0;
}
.footer-navigation li {
    padding:0;
    float:left;
    width:100%;
    margin:0 0 4px;
    @media only screen and (max-width:767px) {
        float:none;
        margin:8px 0;
    }
}
.footer-navigation li:before {
    display:none;
}
.two-columns li {
    width:50%;
    @media only screen and (max-width:767px) {
        width:100%;
    }
}
.footer-navigation a {
    color:@grey;
    .font-size(12);
    text-decoration:none;
    @media only screen and (max-width:720px) {
        .font-size(14);
    }
}
.footer-navigation a:hover {
    text-decoration:underline;
}
.footer-lower {
    color:#fff;
    padding:30px 0 40px;
    background-color:@grey;
}
.copyright {
    display:block;
    .font-size(12);
    margin:0 0 30px;
}
.smallprint {
    display:block;
    .font-size(9);
    line-height:1.4;
}
.smallprint a {
    color:#fff;
}
.shire-logo {
    position:absolute;
    top:-10px;
    right:0;
    overflow:hidden;
    .size(96px,28px);
    text-indent:-9999px;
    background:url('../images/shire.svg') 50% no-repeat;
    background-size:contain;
}
