/******************************************************************************************/
/* Typography, Variables & Mixins
/******************************************************************************************/

@baseFontSize        : 16;
@allow               : #72c34b;
@deny                : #ea4053;
@orange              : #193e6d; /*#f8971d;*/
@light-orange        : #2960a5;/*#f9ac4a;*/
@green               : #008a5e;
@grey                : #6a6d72;
@blue                : #446DFF;
@lightgrey           : #8d8d8f;
@ultralightgrey      : #f3f3f3;
@bodyfont            : 'Helvetica Neue', Arial, sans-serif;

.font-size(@font-size: @baseFontSize) {
    @rem: (@font-size / @baseFontSize);
    font-size: @font-size * 1px;
    font-size: ~"@{rem}rem";
}
.clearfix() {
	*zoom:1;
	&:before,
	&:after {
	    content:"";
	    display:table;
	}
	&:after {
	    clear:both;
	}
}
.inline-block() {
	display:inline-block;
	*display:inline;
	*zoom:1;
}
.size(@width, @height) {
	width:@width;
	height:@height;
}
.square(@size) {
	.size(@size, @size);
}
.text-overflow() {
	overflow:hidden;
	white-space:nowrap;
	text-overflow:ellipsis;
}
.hidden {
	display:none;
}
.unlisted() {
	list-style:none;
	padding:0;
	margin:0;
}
.transition(@transition1,@transition2:X){
    @value: ~`"@{arguments}".replace(/[\[\]]|\,\sX/g, '')`;
    -webkit-transition: @value;
       -moz-transition: @value;
        -ms-transition: @value;
         -o-transition: @value;
            transition: @value;
}
.transform(@transform) {
  -webkit-transform: @transform;
     -moz-transform: @transform;
      -ms-transform: @transform;
       -o-transform: @transform;
          transform: @transform;
}
.border-radius(@radius) {
  -webkit-border-radius: @radius;
     -moz-border-radius: @radius;
          border-radius: @radius;
}
.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow;
     -moz-box-shadow: @shadow;
          box-shadow: @shadow;
}
.box-sizing(@sizing) {
  -webkit-box-sizing: @sizing;
     -moz-box-sizing: @sizing;
      -ms-box-sizing: @sizing;
          box-sizing: @sizing;
}
.appearance(@appearance) {
  -webkit-appearance: @appearance;
     -moz-appearance: @appearance;
          appearance: @appearance;
}
.opacity(@opacity) {
	opacity:@opacity / 100;
	filter:~"alpha(opacity=@{opacity})";
}
.image-2x(@image, @width, @height) {
    @media
    only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (   min--moz-device-pixel-ratio: 1.25),
    only screen and (     -o-min-device-pixel-ratio: 1.25/1),
    only screen and (        min-device-pixel-ratio: 1.25),
    only screen and (                min-resolution: 192dpi),
    only screen and (                min-resolution: 1.25dppx) {
        background-image:~`"url(@{image})"`;
        background-size:@width @height;
    }
}
.gradient(){
    background: #193e6d; /* Old browsers */
    background: -moz-linear-gradient(left,  #193e6d 0%, #2960a5 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #193e6d 0%,#2960a5 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #193e6d 0%,#2960a5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#193e6d', endColorstr='#2960a5',GradientType=1 ); /* IE6-9 */
}
