/******************************************************************************************/
/* Grid System
/******************************************************************************************/

.row {
    position:relative;
    .clearfix();
}
.column {
    float:left;
    width:100%;
    .clearfix();
    padding:10px;
    .box-sizing(border-box);
}

/* Width classes for each span */
.span-1 {
    width:8.33333333333%;
}
.span-2 {
    width:16.6666666667%;
}
.span-3 {
    width:25.0%;
}
.span-4 {
    width:33.3333333333%;
}
.span-5 {
    width:41.6666666667%;
}
.span-6 {
    width:50.0%;
}
.span-7 {
    width:58.3333333333%;
}
.span-8 {
    width:66.6666666667%;
}
.span-9 {
    width:75.0%;
}
.span-10 {
    width:83.3333333333%;
}
.span-11 {
    width:91.6666666667%;
}
.span-12 {
    float:none;
    width:100%;
    padding:10px 0;
}
.column {
    @media only screen and (max-width:740px) {
        width:100%;
        padding:10px 0;
    }
}
.padding-left {
    padding-left:0;
}
.padding-right {
    padding-right:0;
}
