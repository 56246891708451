/******************************************************************************************/
/* Modules - Buttons
/******************************************************************************************/

.btn {
    border:0;
    outline:0;
    color:#fff;
    display:block;
    .font-size(16);
    .inline-block();
    font-weight:300;
    text-align:center;
    .border-radius(0);
    .appearance(none);
    text-decoration:none;
    font-family:@bodyfont;
    .transition(all 250ms ease-in-out);
}
.callout-btn {
    color:#fff;
    font-weight:500;
    border:1px solid #fff;
    background-color:@orange;
}
.callout-btn:hover {
    background-color:darken(@orange, 6%);
}

/******************************************************************************************/
/* Modules - Image Wells
/******************************************************************************************/

.image-well {
    display:block;
    margin:24px 0 0;
}
.column-caption,
.image-well-caption {
    padding:20px;
    text-align:left;
    background-color:#ededed;
    border:1px solid #ededed;
}
.image-well-caption cite {
    font-style:normal;
}
.pop-image {
    position:relative;
}
.column-image,
.image-well-image {
    position:relative;
    padding:20px;
    text-align:center;
    border:1px solid @orange;
}
.column-image img,
.image-well-image img {
    .inline-block();
    vertical-align:middle;
}
// .pop-image:before,
// .column-image:before,
// .image-well-image:before {
//     @media only screen and (max-width:600px) {
//         position:absolute;
//         top:10px;
//         right:10px;
//         content:'';
//         display:block;
//         overflow:hidden;
//         .size(30px,30px);
//         background:rgba(255,255,255,0.5) url('../images/zoom.svg') 50% no-repeat;
//         background-size:20px 20px;
//     }
// }

/******************************************************************************************/
/* Modules - Video Wells
/******************************************************************************************/

.video-well {
    position:relative;
    display:block;
    padding:0;
    margin:24px 0 0;
    border:6px solid #666;
}
.video-well-left {
    float:left;
    margin:32px 24px 0 0;
    @media only screen and (max-width:720px) {
        float:none;
        margin:0;
    }
}
.video-well-right {
    float:right;
    margin:32px 0 0 24px;
    @media only screen and (max-width:720px) {
        float:none;
        margin:0;
    }
}
.video-well-smaller {
    margin:0 auto;
    width:220px;
}
.video-well img {
    display:block;
}
.video-well .video-well-overlay {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    overflow:hidden;
    text-indent:-9999px;
    background:rgba(102, 102, 102, 0.5) url('../images/play-btn.png') 50% no-repeat;
    background-size:39px 51px;
}

/******************************************************************************************/
/* Modules - Text Wells
/******************************************************************************************/

.text-well {
    position:relative;
    overflow:hidden;
    padding:20px;
    margin:24px 0 0;
    background-color:#f3f3f3;
    border:2px dotted #7e7c80;
}
.text-well h2 {
    margin:0;
    .font-size(20);
}
.text-well li {
    width:33%;
    float:left;
    .font-size(14);
    padding:0 18px;
    .box-sizing(border-box);
    @media only screen and (max-width:720px) {
        float:none;
        width:100%;
    }
}

/******************************************************************************************/
/* Modules - Callout Wells
/******************************************************************************************/

.callout-well {
    color:@grey;
    padding:20px;
    line-height:1.4em;
    text-align:center;
    background-color:#f6dfc0;
}

/******************************************************************************************/
/* Panels - Hero Area
/******************************************************************************************/

.panel-hero-area {
    position:relative;
    overflow:hidden;
    width:100%;
}
.panel-hero-area .inner {
    max-width:820px;
    @media only screen and (max-width:720px) {
        width:100%;
    }
}
.panel-hero-area img {
    width:100%;
    display:block;
    min-width:600px;
    @media only screen and (max-width:600px) {
        position:relative;
        left:50%;
        .transform(translateX(-50%));
    }
}
.hero-area-caption {
    position:absolute;
    bottom:0;
    left:0;
    @media only screen and (max-width:720px) {
        position:relative;
        bottom:auto;
        right:auto;
        left:auto;
    }
}
.hero-area-caption .first-line {
    position:relative;
    color:#fff;
    z-index:2;
    line-height:1;
    display:block;
    .font-size(40);
    font-weight:300;
    padding:16px 20px 4px;
    background:url('../images/orange.png') 0 0 repeat;
    @media only screen and (max-width:720px) {
        .font-size(28);
        line-height:1.2;
        padding:12px 20px 4px;
    }
}
.hero-area-caption .second-line {
    position:relative;
    color:#fff;
    z-index:2;
    line-height:1;
    display:block;
    .font-size(40);
    font-weight:300;
    padding:2px 20px 16px;
    @media only screen and (max-width:720px) {
        .font-size(28);
        padding:0 20px 12px;
        background:url('../images/orange.png') 0 0 repeat;
    }
}
.hero-area-caption .single-line {
    position:relative;
    color:#fff;
    z-index:2;
    display:block;
    .font-size(40);
    line-height:1.1;
    font-weight:300;
    padding:8px 20px 8px;
    @media only screen and (max-width:720px) {
        .font-size(28);
        line-height:1.2;
        padding:12px 20px;
        background:url('../images/orange.png') 0 0 repeat;
    }
}
.hero-area-caption:before {
    position:absolute;
    bottom:0;
    left:0;
    z-index:1;
    content:'';
    height:58px;
    width:2000px;
    display:block;
    background:url('../images/orange-with-swirl.png') 0 0 no-repeat;
    @media only screen and (max-width:720px) {
        display:none;
    }
}
.panel-hero-area-alt {
    padding:0 0 44px;
    @media only screen and (max-width:980px) {
        padding:0;
    }
}
.hero-area-subtitle {
    position:absolute;
    bottom:-44px;
    width:110%;
    color:#fff;
    height:44px;
    .font-size(12);
    padding:0 20px;
    line-height:46px;
    background-color:@light-orange;
    @media only screen and (max-width:720px) {
        position:relative;
        bottom:auto;
        left:auto;
        width:100%;
        margin:0;
        height:auto;
        padding:12px;
        line-height:1.4;
        .box-sizing(border-box);
        background-color:darken(@light-orange, 5%);
    }
}

/******************************************************************************************/
/* Panels - Homepage Callout
/******************************************************************************************/

.panel-homepage-callout {
    position:relative;
    color:#fff;
    height:325px;
    margin:30px 0 0;
    overflow:hidden;
    text-align:center;
    background:@orange url('../images/homepage-callout.jpg') 50% 0 no-repeat;
    @media only screen and (max-width:980px) {
        height:auto;
        padding:0 0 42px;
        background-size:cover;
        background-position:top right;
    }
    @media only screen and (max-width:720px) {
        background-image:none;
        margin:30px auto 0;
        width:92%;
    }
}
.panel-homepage-callout .inner {
    max-width:820px;
}
.homepage-callout-info {
    width:50%;
    @media only screen and (max-width:820px) {
        width:60%;
    }
    @media only screen and (max-width:720px) {
        width:100%;
    }
}
.homepage-callout-info p {
    .font-size(18);
    font-weight:700;
    text-align:left;
    margin:42px 0 0;
    @media only screen and (max-width:820px) {
        .font-size(16);
        margin:24px 0 0;
    }
}
.homepage-callout-info p + p {
    margin:12px 0 0;
    @media only screen and (max-width:720px) {
        margin:24px 0 0;
    }
}

/******************************************************************************************/
/* Panels - Sources
/******************************************************************************************/

.panel-sources {
    position:relative;
    padding:30px 0;
}
.panel-sources ol,
.panel-sources ul {
    margin:14px 0 0;
}
.panel-sources p,
.panel-sources li {
    .font-size(10);
    margin:0 0 4px;
    @media only screen and (max-width:720px) {
        .font-size(14);
    }
}
.panel-sources ul li:before {
    top:5px;
    @media only screen and (max-width:720px) {
        top:7px;
    }
}
.panel-sources ol li:before {
    margin-right:7px;
}
.panel-sources h3 {
    color:@orange;
    .font-size(14);
    font-weight:700;
    @media only screen and (max-width:720px) {
        .font-size(16);
    }
}
.float-right {
    float:right;
    margin:24px 0 24px 24px;
    @media only screen and (max-width:720px) {
        float:none;
        margin:24px auto;
    }
}

/******************************************************************************************/
/* Panels - Flexible Layouts
/******************************************************************************************/

.panel-flexible {
    position:relative;
    overflow:hidden;
    margin:0;
    padding:0;
}
.panel-flexible-white {
    background-color:#fff;
}
.panel-flexible-orange {
    color:#fff;
    background-color:@orange;
}
.panel-flexible-orange li:before {
    color:#fff;
    background-color:#fff;
}
.panel-flexible-orange h2 {
    color:#fff;
}
.panel-flexible-orange blockquote {
    border-color:#fff;
}
.panel-flexible-orange blockquote p {
    color:#fff;
}
.panel-flexible-orange figure {
    background-color:#fff;
}
.panel-flexible-orange .image-well-caption {
    color:@grey;
    border-color:#e8cba7;
    background-color:#e8cba7;
}
.panel-flexible-orange .image-well-image {
    border-color:#fef5e9;
    background-color:#fef5e9;
}
.panel-flexible-grey {
    color:#fff;
    background-color:@grey;
}
.panel-flexible-grey h2 {
    color:#fff;
}
.panel-flexible-grey blockquote {
    border-color:#fff;
}
.panel-flexible-grey blockquote p {
    color:#fff;
}
.panel-flexible-grey figure {
    border-color:#fff;
    background-color:#fff;
}
.panel-flexible-grey .image-well-caption {
    color:@grey;
    border-color:#bebfc0;
    background-color:#bebfc0;
}
.panel-flexible-grey .image-well-image {
    border-color:#fff;
    background-color:#fff;
}

/******************************************************************************************/
/* Panels - Lightbox
/******************************************************************************************/

// .revestive-popup-wrap.mfp-hide {
//     @media only screen and (max-width:600px) {
//         display:block !important;
//     }
// }
.revestive-popup-wrap {
    position:relative;
	margin:0 auto;
	width:70%;
	@media only screen and (max-width:600px) {
        width:100%;
    }
}
.revestive-popup {
    color:#fff;
    padding:30px;
    margin:120px 0;
    background-color:#6a6d71;
    border:1px solid @orange;
    @media only screen and (max-width:600px) {
        border:0;
        margin:0;
        overflow:hidden;
        padding:0 16px 22px;
    }
}
.revestive-popup h2 {
    color:#fff;
    .font-size(24);
    font-weight:700;
    margin:22px 0 0;
}
.revestive-popup h3 {
    color:#fff;
    .font-size(14);
    font-weight:700;
    margin:22px 0 0;
}
.revestive-popup em {
    display:block;
    .font-size(14);
    margin:22px 0 0;
}
.revestive-popup p {
    display:block;
    .font-size(14);
    margin:22px 0 0;
}
.revestive-popup small,
.revestive-popup small em {
    display:block;
    .font-size(11);
    margin:22px 0 0;
}
.revestive-popup table {
    color:@grey;
    margin:22px 0 0;
    background-color:#d3d3d5;
}
.revestive-popup th,
.revestive-popup td {
    .font-size(14);
    line-height:1.3;
    padding:10px 20px;
    vertical-align:top;
    border-bottom:1px solid #6a6d71;
    @media only screen and (max-width:480px) {
        padding:10px;
    }
}
.revestive-popup td:first-child {
    width:140px;
    @media only screen and (max-width:480px) {
        width:auto;
    }
}
.revestive-popup ul ul li:before {
    color:@orange;
}
.revestive-popup .prescribing-information {
    margin:22px auto 0;
}
.revestive-popup .prescribing-information td {
    text-align:center;
}
#prescribing-information {
    width:480px;
    @media only screen and (max-width:480px) {
        width:100%;
    }
}

/******************************************************************************************/
/* Panels - Master
/******************************************************************************************/

.panel-master-content {
    @media only screen and (max-width:720px) {
        padding:20px 0 0;
    }
}
.dosing-information {
    color:@grey;
    margin:22px 0 0;
    background-color:@orange;
}
.dosing-information td {
    width:50%;
    .font-size(14);
    line-height:1.3;
    padding:6px 20px;
    text-align:center;
    vertical-align:top;
    background-color:#fbefe0;
    border:1px solid @orange;
}
.dosing-information th {
    color:#fff;
    text-align:left;
    padding:10px 20px;
    background-color:@orange;
}
.dosing-information td:first-child {
    background-color:#ecebeb;
}

/******************************************************************************************/
/* Module - Contact Form
/******************************************************************************************/

.panel-flexible-content div.wpcf7 {
    position:relative;
    margin:40px auto;
    overflow:hidden;
    width:90%;
    @media only screen and (max-width:580px) {
        width:100%;
        margin:20px auto;
    }
}
.panel-flexible-content div.wpcf7 fieldset {
    position:relative;
    margin:0 0 20px;
    float:left;
    width:45%;
    @media only screen and (max-width:580px) {
        width:100%;
        float:none;
        margin:10px 0;
    }
}
.panel-flexible-content div.wpcf7 img.ajax-loader {
    margin:12px auto 0;
}
.panel-flexible-content div.wpcf7 .wpcf7-not-valid-tip {
    position:absolute;
    top:0;
    right:0;
    color:#fff;
    padding:14px;
    display:block;
    .font-size(14);
    background-color:@deny;
}
.panel-flexible-content div.wpcf7 input:focus + .wpcf7-not-valid-tip {
    display:none;
}
.panel-flexible-content div.wpcf7 div.wpcf7-response-output {
    border:0;
    color:#fff;
    float:left;
    width:100%;
    padding:14px;
    outline:none;
    .font-size(16);
    margin:40px 0 0;
}
.panel-flexible-content div.wpcf7 div.wpcf7-validation-errors {
    background-color:@deny;
}
.panel-flexible-content div.wpcf7 div.wpcf7-mail-sent-ok {
    background-color:@allow;
}
.panel-flexible-content div.wpcf7 .wpcf7-form-control-wrap {
    display:block;
}
.panel-flexible-content div.wpcf7 span.wpcf7-list-item {
    margin:0;
}
.panel-flexible-content div.wpcf7 .checkboxes {
    position:relative;
}
.panel-flexible-content div.wpcf7 .checkboxes label,
.panel-flexible-content div.wpcf7 .checkboxes .label,
.panel-flexible-content div.wpcf7 .wpcf7-list-item-label {
    position:relative;
    margin:0;
    width:auto;
    .font-size(15);
    cursor:default;
    .inline-block();
    line-height:22px;
}
.panel-flexible-content div.wpcf7 .wpcf7-list-item-label {
    display:inline;
}
.panel-flexible-content div.wpcf7 .checkboxes input[type='checkbox'] {
    border:0;
    content:'';
    outline:none;
    .square(22px);
    cursor:pointer;
    .inline-block();
    .appearance(none);
    .border-radius(0);
    margin:2px 6px 6px 0;
    vertical-align:middle;
    background:url('../images/checkboxes.png') no-repeat;
    .image-2x('../images/checkboxes@2x.png', 22px, 88px);
}
.panel-flexible-content div.wpcf7 .checkboxes input[type='checkbox']:checked {
    background-position:0 -44px;
}
.panel-flexible-content div.wpcf7 .checkboxes input[type='checkbox']:focus,
.panel-flexible-content div.wpcf7 .checkboxes input[type='checkbox']:active {
    outline:none;
}
.panel-flexible-content div.wpcf7 textarea,
.panel-flexible-content div.wpcf7 input[type=text],
.panel-flexible-content div.wpcf7 input[type=email],
.panel-flexible-content div.wpcf7 input[type=confirm_email] {
    color:@grey;
    width:100%;
    height:50px;
    padding:14px;
    outline:none;
    display:block;
    line-height:1;
    .font-size(16);
    .appearance(none);
    .border-radius(0);
    background-color:#fff;
    vertical-align:middle;
    .box-sizing(border-box);
    border:1px solid @orange;
}
.panel-flexible-content div.wpcf7 textarea {
    line-height:20px;
    resize:vertical;
    min-height:200px;
}
.panel-flexible-content div.wpcf7 .select {
    position:relative;
    width:45%;
    padding:0;
    height:50px;
    outline:none;
    .inline-block();
    .border-radius(0);
    .appearance(none);
    .box-sizing(border-box);
    background-color:#fff;
    vertical-align:middle;
    border:1px solid @orange;
    @media only screen and (max-width:580px) {
        width:100%;
        float:none;
        display:block;
        margin:10px 0 0;
    }
}
.panel-flexible-content div.wpcf7 .select:before {
    position:absolute;
    top:0;
    right:0;
    content:'';
    width:40px;
    height:48px;
    background:#fff url('../images/dropdown.png') 0 50% no-repeat;
    .image-2x('../images/dropdown@2x.png', 31px, 40px);
}
.panel-flexible-content div.wpcf7 select {
    position:relative;
    z-index:2;
    border:0;
    width:100%;
    color:@grey;
    height:48px;
    outline:none;
    cursor:pointer;
    .inline-block();
    background:none;
    .font-size(16px);
    .box-shadow(none);
    .appearance(none);
    .border-radius(0);
    .box-sizing(border-box);
    padding:14px 40px 14px 14px;
}
.panel-flexible-content div.wpcf7 select:-ms-expand,
.panel-flexible-content div.wpcf7 select::-ms-expand {
    display:none;
}
.panel-flexible-content div.wpcf7 input[type=reset] {
    border:0;
    width:100%;
    color:#fff;
    outline:none;
    margin:0 auto;
    display:block;
    line-height:1;
    .font-size(16);
    font-weight:500;
    .border-radius(0);
    .appearance(none);
    padding:14px 32px;
    vertical-align:middle;
    font-family:@bodyfont;
    .box-sizing(border-box);
    text-transform:uppercase;
    background:@grey url('../images/arrow-white.svg') 90% 50% no-repeat;
    background-size:15px 20px;
}
.panel-flexible-content div.wpcf7 input[type=submit] {
    border:0;
    width:100%;
    color:#fff;
    outline:none;
    margin:0 auto;
    display:block;
    line-height:1;
    .font-size(16);
    font-weight:500;
    .border-radius(0);
    .appearance(none);
    padding:14px 32px;
    vertical-align:middle;
    font-family:@bodyfont;
    .box-sizing(border-box);
    text-transform:uppercase;
    background:@orange url('../images/arrow-white.svg') 90% 50% no-repeat;
    background-size:15px 20px;
}
.panel-flexible-content div.wpcf7 input[type=submit]:disabled {
    cursor:default;
    background-color:#ccc;
}
.panel-flexible-content div.wpcf7 .form-spacer {
    height:50px;
    @media only screen and (max-width:580px) {
        display:none;
    }
}
.panel-flexible-content div.wpcf7 .margin-left {
    margin-left:5%;
    @media only screen and (max-width:580px) {
        margin:10px 0;
    }
}
.panel-flexible-content div.wpcf7 .full-width {
    float:left;
    width:100%;
}
.panel-flexible-content div.wpcf7 .full-width .confirm {
    .inline-block();
}
.panel-flexible-content div.wpcf7 .full-width .checkboxes {
    width:100%;
    margin:20px 0 0;
}
.panel-flexible-content div.wpcf7 .full-width .submit-wrap {
    width:100%;
    margin:40px 0 0;
    overflow:hidden;
}
.panel-flexible-content div.wpcf7 .full-width .submit-wrap input[type=reset],
.panel-flexible-content div.wpcf7 .full-width .submit-wrap input[type=submit] {
    width:20%;
    float:left;
    margin:0 5% 0 0;
    @media only screen and (max-width:900px) {
        width:40%;
    }
    @media only screen and (max-width:580px) {
        width:100%;
        margin:10px 0;
    }
}

/******************************************************************************************/
/* Panels - Splash
/******************************************************************************************/

.panel-splash {
    position:relative;
    overflow:hidden;
    width:100%;
}
.panel-splash .inner {
    position:static;
    max-width:820px;
}
.panel-splash .inner:after {
    position:absolute;
    left:2%;
    right:2%;
    bottom:0;
    content:'';
    height:4px;
    display:block;
    background-color:@orange;
}
.panel-splash img {
    width:100%;
    display:block;
    @media only screen and (max-width:980px) {
        width:980px;
        max-width:980px;
    }
    @media only screen and (max-width:900px) {
        width:900px;
        max-width:900px;
    }
}
.splash-caption {
    position:absolute;
    top:280px;
    left:auto;
    @media only screen and (max-width:980px) {
        top:180px;
    }
}
.splash-caption .first-line {
    position:relative;
    color:#fff;
    z-index:2;
    line-height:1;
    display:block;
    .font-size(42);
    font-weight:300;
    padding:16px 20px 4px;
    background:url('../images/orange.png') 0 0 repeat;
    @media only screen and (max-width:480px) {
        .font-size(32);
    }
}
.splash-caption .second-line {
    position:relative;
    color:#fff;
    z-index:2;
    line-height:1;
    display:block;
    .font-size(42);
    font-weight:300;
    padding:0 20px 16px;
    @media only screen and (max-width:480px) {
        .font-size(32);
    }
}
.splash-caption:before {
    position:absolute;
    bottom:0;
    left:0;
    z-index:1;
    content:'';
    height:58px;
    width:2000px;
    display:block;
    background:url('../images/orange-with-swirl.png') 0 0 no-repeat;
}
.splash-confirm {
    position:absolute;
    top:410px;
    left:auto;
    color:#fff;
    padding:12px;
    background:url('../images/orange.png') 0 0 repeat;
    @media only screen and (max-width:980px) {
        top:310px;
    }
}
.splash-confirm a {
    color:#fff;
}
.splash-btn {
    position:absolute;
    top:500px;
    left:50%;
    color:#fff;
    display:block;
    line-height:1;
    .font-size(30);
    padding:8px 60px;
    border:1px solid #fff;
    .transform(translateX(-50%));
    @media only screen and (max-width:980px) {
        top:auto;
        bottom:20px;
    }
    @media only screen and (max-width:560px) {
        width:280px;
        padding:8px 0;
    }
}
.splash-btn:hover {
    color:@orange;
    background-color:#fff;
}
.disabled,
.disabled:hover {
    cursor:none;
    pointer-events:none;
}
.panel-splash .checkboxes {
    position:relative;
}
.panel-splash .splash-confirm br {
    @media only screen and (max-width:580px) {
        display:none;
    }
}
.panel-splash .checkboxes label {
    position:relative;
    margin:0;
    width:auto;
    cursor:pointer;
    .inline-block();
    line-height:22px;
}
.panel-splash .checkboxes label p {
    margin:0;
    display:inline;
}
.panel-splash .checkboxes input[type='checkbox'] {
    position:absolute;
    top:0;
    right:0;
    .opacity(0);
}
.panel-splash .checkboxes label p:after {
    content:'';
    .square(22px);
    .inline-block();
    margin:-3px 0 0 12px;
    vertical-align:middle;
    background:url('../images/checkboxes.png') no-repeat;
    .image-2x('../images/checkboxes@2x.png', 22px, 88px);
}
// .panel-splash .checkboxes input[type='checkbox']:checked + label p:after {
//     background-position:0 -44px;
// }

/******************************************************************************************/
/* Panels - Legal
/******************************************************************************************/

.tab-nav {
    font-size:0;
    .unlisted();
    text-align:center;
    background-color:@grey;
    border-top:2px solid #fff;
}
.tab-nav li {
    margin:0;
    padding:0;
    .inline-block();
    vertical-align:top;
    @media only screen and (max-width:980px) {
        display:block;
    }
}
.tab-nav li:before {
    display:none;
}
.tab-nav a {
    color:#fff;
    display:block;
    line-height:1;
    .font-size(16);
    text-decoration:none;
    padding:14px 62px 12px;
}
.tab-nav a:hover {
    text-decoration:underline;
}
.tab-nav .current_page_item a,
.tab-nav .current_page_item a:hover {
    color:@grey;
    text-decoration:none;
    background-color:#fff;
    @media only screen and (max-width:980px) {
        background-color:#f9f9f9;
    }
}
.breadcrumbs {
    .unlisted();
    padding:20px 0;
    overflow:hidden;
}
.breadcrumbs li {
    position:relative;
    margin:0;
    float:left;
    padding:0 10px 0 11px;
}
.breadcrumbs li:first-child {
    padding:0 10px 0 0;
}
.breadcrumbs li:before {
    top:4px;
    bottom:4px;
    margin:0;
    width:1px;
    height:auto;
    background-color:@grey;
}
.breadcrumbs li:first-child:before {
    display:none;
}
.breadcrumbs a,
.breadcrumbs span {
    color:@grey;
    .font-size(13);
    text-decoration:none;
}
.breadcrumbs a:hover {
    text-decoration:underline;
}

/******************************************************************************************/
/* Panels - Accordion
/******************************************************************************************/

.accordion-item {
    position:relative;
    padding:0;
    margin:10px 0;
}
.accordion-bar {
    position:relative;
    display:block;
    color:@grey;
    .font-size(20);
    font-weight:700;
    max-width:928px;
    margin:0 auto 10px;
    text-decoration:none;
    border-top:1px solid @grey;
    padding:16px 46px 16px 16px;
    border-bottom:1px solid @grey;
    background-color:@ultralightgrey;
}
.accordion-bar:after {
    position:absolute;
    top:50%;
    right:16px;
    content:'';
    overflow:hidden;
    margin:-8px 0 0;
    .size(20px,16px);
    background:url('../images/arrow-orange-down.svg') 50% no-repeat;
    background-size:contain;
}
.open-accordion-bar:after {
    background:url('../images/arrow-orange-up.svg') 50% no-repeat;
    background-size:contain;
}
.accordion-content {
    display:none;
}
.accordion-content-inner {
    overflow:hidden;
}
.open-accordion-content {
    display:block;
}

/******************************************************************************************/
/* Panels - Overrides
/******************************************************************************************/

.panel-homepage-callout-alt img {
    margin:24px 0 0;
}
.panel-homepage-thin {
    padding-top:0;
}
.panel-homepage-thin .inner,
.panel-flexible-legal .inner  {
    max-width:820px;
}
.panel-flexible-stats {
    font-size:0;
}
.panel-flexible-stats .column {
    float:none;
    .inline-block();
    vertical-align:middle;
}
.panel-flexible-stats .stats-gradient {
    color:#fff;
    .gradient();
    .font-size(22);
    overflow:hidden;
    @media only screen and (max-width:740px) {
        .font-size(16);
    }
}
.panel-flexible-stats .stats-gradient p {
    margin:0;
    font-weight:600;
    line-height:1.3;
}
.panel-flexible-stats .stats-gradient .large {
    line-height:1;
    .font-size(42);
    @media only screen and (max-width:740px) {
        .font-size(24);
    }
}
.stats-gradient-left {
    margin:0 -42px 0 -42px;
    padding:30px 62px 40px 40px;
    @media only screen and (max-width:740px) {
        margin:0;
        padding:30px;
    }
}
.stats-image-left img {
    width:100%;
    float:left;
    margin:0 0 0 -10px;
    @media only screen and (max-width:740px) {
        margin:0;
        float:none;
    }
}
.stats-gradient-right {
    margin:0 -42px 0 -42px;
    padding:30px 40px 40px 62px;
    @media only screen and (max-width:740px) {
        margin:0;
        padding:30px;
    }
}
.stats-image-right img {
    width:100%;
    float:right;
    margin:0 -10px 0 0;
    @media only screen and (max-width:740px) {
        margin:0;
        float:none;
    }
}
.panel-flexible-downloads {
    padding:0 0 24px;
}
.panel-flexible-downloads .row {
    margin:4px 10px 0 -10px;
    @media only screen and (max-width:740px) {
        margin:0;
    }
}
.panel-flexible-grey .download-item,
.panel-flexible-orange .download-item {
    background-color:#fff;
    border:2px solid darken(@orange, 10%);
}
.panel-flexible-white .download-item {
    background-color:@orange;
    border:2px solid darken(@orange, 10%);
}
.download-item {
    padding:20px;
    text-align:center;
}
.download-item .download-preview,
.download-item .download-preview p {
    margin:0;
    padding:0;
    height:132px;
    overflow:hidden;
}
.download-item .download-preview img {
    max-height:100%;
}
.download-item .btn-download {
    position:relative;
    color:#fff;
    margin:14px 0 0;
    font-weight:600;
    border:1px solid #fff;
    text-transform:uppercase;
    background-color:@orange;
    padding:8px 48px 8px 28px;
}
.download-item .btn-download:after {
    position:absolute;
    top:4px;
    right:18px;
    content:'';
    .square(20px);
    overflow:hidden;
    text-indent:-9999px;
    background:url('../images/download-icon.svg') 50% no-repeat;
    background-size:contain;
}
.download-item .btn-download:hover {
    background-color:darken(@orange, 6%);
}
.panel-flexible-grey .download-item h3,
.panel-flexible-orange .download-item h3 {
    color:@grey;
    margin:0 0 14px;
}
.panel-flexible-white .download-item h3 {
    color:#fff;
    margin:0 0 14px;
}
.panel-flexible-white .btn-download {
    color:@grey;
    background-color:#fff;
}

/******************************************************************************************/
/* Panels - Search Results
/******************************************************************************************/

.search-template .panel-hero-area img,
.search-template .panel-sources .inner,
.search-template .panel-hero-area .inner {
    display:none;
}

/******************************************************************************************/
/* Panels - New Window Popup
/******************************************************************************************/

.new-window-bg {
    background-color:@grey;
}
.new-window-popup {
    position:relative;
    margin:0 auto;
    padding:30px;
    max-width:380px;
    overflow:hidden;
    background-color:#fff;
    border:1px solid @orange;
}
.new-window-popup p {
    color:@grey;
    .font-size(20);
    margin:0 0 24px;
    text-align:center;
    @media only screen and (max-width: 720px) {
        .font-size(16);
    }
}
.new-window-popup strong {
    font-weight:700;
}
.new-window-popup a {
    position:relative;
    width:45%;
    color:#fff;
    padding:12px 0;
    .font-size(18);
    font-weight:700;
    .inline-block();
    text-align:center;
    vertical-align:top;
    text-decoration:none;
    @media only screen and (max-width: 720px) {
        .font-size(14);
        padding:10px 0;
    }
}
.new-window-popup a:after {
    position:absolute;
    top:50%;
    right:10px;
    content:'';
    overflow:hidden;
    margin:-10px 0 0;
    .size(15px,20px);
    background:url('../images/arrow-white.svg') 0 0 no-repeat;
    background-size:contain;
    @media only screen and (max-width: 720px) {
        .square(14px);
        margin:-7px 0 0;
    }
}
.new-window-popup .btn-cancel {
    float:left;
    background-color:@grey;
}
.new-window-popup .btn-ok {
    float:right;
    background-color:@orange;
}
.confirm-your-status-popup {
    text-align:center;
}
.confirm-your-status-popup .btn-cancel {
    float:none;
}
