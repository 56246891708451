/* Magnific Popup CSS */
.mfp-bg {
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1042;
    .opacity(85);
	position:fixed;
	background:#fff;
    overflow:hidden;
}
.mfp-wrap {
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1043;
	position:fixed;
	outline:none !important;
	-webkit-backface-visibility:hidden;
}
.mfp-container {
	text-align:center;
	position:absolute;
	width:100%;
	height:100%;
	left:0;
	top:0;
	padding:0 8px;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
}
.mfp-container:before {
	content:'';
	display:inline-block;
	height:100%;
	vertical-align:middle;
}
.mfp-align-top .mfp-container:before {
	display:none;
}
.mfp-content {
	position:relative;
	display:inline-block;
	vertical-align:middle;
	margin:0 auto;
	text-align:left;
	z-index:1045;
}
.mfp-inline-holder .mfp-content,.mfp-ajax-holder .mfp-content {
	width:100%;
	cursor:auto;
}
.mfp-figure figcaption {
	display:none;
}
.mfp-ajax-cur {
	cursor:progress;
}
.mfp-close {
	cursor:pointer;
}
.mfp-zoom {
	cursor:pointer;
	cursor:-webkit-zoom-in;
	cursor:-moz-zoom-in;
	cursor:zoom-in;
}
.mfp-auto-cursor .mfp-content {
	cursor:auto;
}
.mfp-close,.mfp-arrow,.mfp-preloader,.mfp-counter {
	-webkit-user-select:none;
	-moz-user-select:none;
	user-select:none;
}
.mfp-loading.mfp-figure {
	display:none;
}
.mfp-hide {
	display:none !important;
}
.mfp-preloader {
	color:#CCC;
	position:absolute;
	top:50%;
	width:auto;
	text-align:center;
	margin-top:-0.8em;
	left:8px;
	right:8px;
	z-index:1044;
}
.mfp-preloader a {
	color:#CCC;
}
.mfp-preloader a:hover {
	color:#FFF;
}
.mfp-s-ready .mfp-preloader {
	display:none;
}
.mfp-s-error .mfp-content {
	display:none;
}
button.mfp-close,button.mfp-arrow {
	overflow:visible;
	cursor:pointer;
	border:0;
	-webkit-appearance:none;
	display:block;
	outline:none;
	padding:0;
	z-index:1046;
	-webkit-box-shadow:none;
	box-shadow:none;
}
button::-moz-focus-inner {
	padding:0;
	border:0;
}
.mfp-close {
	position:absolute;
	top:12px;
    right:12px;
	width:22px;
	height:22px;
	overflow:hidden;
	line-height:22px;
	text-indent:-9999px;
	text-decoration:none;
	.transition(all 150ms ease-in-out);
	background:url('../images/close-icon.svg') 0 0 no-repeat;
    background-size:contain;
}
.mfp-counter {
	position:absolute;
	top:0;
	right:0;
	color:#CCC;
	font-size:12px;
	line-height:18px;
	white-space:nowrap;
}
.mfp-arrow {
	position:absolute;
	.opacity(65);
	margin:0;
	top:50%;
	margin-top:-55px;
	padding:0;
	width:90px;
	height:110px;
	-webkit-tap-highlight-color:transparent;
}
.mfp-arrow:hover,.mfp-arrow:focus {
	.opacity(100);
}
.mfp-arrow:before,.mfp-arrow:after,.mfp-arrow .mfp-b,.mfp-arrow .mfp-a {
	content:'';
	display:block;
	width:0;
	height:0;
	position:absolute;
	left:0;
	top:0;
	margin-top:35px;
	margin-left:35px;
	border:medium inset transparent;
}
.mfp-arrow:after,.mfp-arrow .mfp-a {
	border-top-width:13px;
	border-bottom-width:13px;
	top:8px;
}
.mfp-arrow:before,.mfp-arrow .mfp-b {
	border-top-width:21px;
	border-bottom-width:21px;
	.opacity(70);
}
.mfp-arrow-left {
	left:0;
}
.mfp-arrow-left:after,.mfp-arrow-left .mfp-a {
	border-right:17px solid #FFF;
	margin-left:31px;
}
.mfp-arrow-left:before,.mfp-arrow-left .mfp-b {
	margin-left:25px;
	border-right:27px solid #3F3F3F;
}
.mfp-arrow-right {
	right:0;
}
.mfp-arrow-right:after,.mfp-arrow-right .mfp-a {
	border-left:17px solid #FFF;
	margin-left:39px;
}
.mfp-arrow-right:before,.mfp-arrow-right .mfp-b {
	border-left:27px solid #3F3F3F;
}
.mfp-iframe-holder {
	padding-top:40px;
	padding-bottom:40px;
}
.mfp-iframe-holder .mfp-content {
	line-height:0;
	width:100%;
	max-width:900px;
}
.mfp-iframe-scaler {
	width:100%;
	height:0;
	overflow:hidden;
	padding-top:56.25%;
}
.mfp-iframe-scaler iframe {
	position:absolute;
	display:block;
	top:0;
	left:0;
	width:100%;
	height:100%;
	box-shadow:0 0 8px rgba(0,0,0,0.6);
	background: #000;
}
.mfp-iframe-scaler .mfp-close {
	top:-36px;
	right:-36px;
	border:6px solid #6a6d71;
	background-color:#6a6d71;
}
/* The shadow behind the image */
.mfp-figure:after {
	content:'';
	position:absolute;
	left:0;
	top:40px;
	bottom:40px;
	display:block;
	right:0;
	width:auto;
	height:auto;
	z-index:-1;
	box-shadow:0 0 8px rgba(0,0,0,0.6);
	background:#444;
}
.mfp-figure small {
	color:#BDBDBD;
	display:block;
	font-size:12px;
	line-height:14px;
}
.mfp-figure figure {
	margin:0;
}
.mfp-bottom-bar {
	margin-top:-36px;
	position:absolute;
	top:100%;
	left:0;
	width:100%;
	cursor:auto;
}
.mfp-title {
	text-align:left;
	line-height:18px;
	color:#F3F3F3;
	word-wrap:break-word;
	padding-right:36px;
}
.mfp-image-holder .mfp-content {
	max-width:100%;
}
.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer;
}
/*** Fade-zoom animation*/
/* start state */
.my-mfp-zoom-in .mfp-content {
	.opacity(0);
	-webkit-transition:all 0.2s ease-in-out;
	-moz-transition:all 0.2s ease-in-out;
	-o-transition:all 0.2s ease-in-out;
	transition:all 0.2s ease-in-out;
	-webkit-transform:scale(0.8);
	-moz-transform:scale(0.8);
	-ms-transform:scale(0.8);
	-o-transform:scale(0.8);
	transform: scale(0.8);
}
/* animate in */
.my-mfp-zoom-in.mfp-ready .mfp-content {
	.opacity(100);
	-webkit-transition:all 0.2s ease-in-out;
	-moz-transition:all 0.2s ease-in-out;
	-o-transition:all 0.2s ease-in-out;
	transition:all 0.2s ease-in-out;
	-webkit-transform:scale(1);
	-moz-transform:scale(1);
	-ms-transform:scale(1);
	-o-transform:scale(1);
	transform: scale(1);
}
/* animate out */
.my-mfp-zoom-in.mfp-removing .mfp-content {
	.opacity(0);
	-webkit-transition:all 0.2s ease-in-out;
	-moz-transition:all 0.2s ease-in-out;
	-o-transition:all 0.2s ease-in-out;
	transition:all 0.2s ease-in-out;
	-webkit-transform:scale(0.8);
	-moz-transform:scale(0.8);
	-ms-transform:scale(0.8);
	-o-transform:scale(0.8);
	transform: scale(0.8);
}
/* Dark overlay,start state */
.my-mfp-zoom-in.mfp-bg {
	.opacity(0);
	-webkit-transition:opacity 0.2s ease-in-out;
	-moz-transition:opacity 0.2s ease-in-out;
	-o-transition:opacity 0.2s ease-in-out;
	transition: opacity 0.2s ease-in-out;
}
/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
	.opacity(80);
	-webkit-transition:opacity 0.2s ease-in-out;
	-moz-transition:opacity 0.2s ease-in-out;
	-o-transition:opacity 0.2s ease-in-out;
	transition: opacity 0.2s ease-in-out;
}
/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
	.opacity(0);
	-webkit-transition:opacity 0.2s ease-in-out;
	-moz-transition:opacity 0.2s ease-in-out;
	-o-transition:opacity 0.2s ease-in-out;
	transition: opacity 0.2s ease-in-out;
}
@media screen and (max-width:800px) and (orientation:landscape),screen and (max-height:300px) {
	/** * Remove all paddings around the image on small screen */
	.mfp-img-mobile .mfp-image-holder {
		padding-left:0;
		padding-right:0;
	}
	.mfp-img-mobile img.mfp-img {
		padding: 0;
	}
	/* The shadow behind the image */
	.mfp-img-mobile .mfp-figure:after {
		top:0;
		bottom:0;
	}
	.mfp-img-mobile .mfp-bottom-bar {
		background:rgba(0,0,0,0.6);
		bottom:0;
		margin:0;
		top:auto;
		padding:3px 5px;
		position:fixed;
		-webkit-box-sizing:border-box;
		-moz-box-sizing:border-box;
		box-sizing:border-box;
	}
	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding:0;
	}
	.mfp-img-mobile .mfp-counter {
		right:5px;
		top:3px;
	}
	.mfp-img-mobile .mfp-close {
		top:0;
		right:0;
		width:35px;
		height:35px;
		line-height:35px;
		background:rgba(0,0,0,0.6);
		position:fixed;
		text-align:center;
		padding:0;
	}
	.mfp-img-mobile .mfp-figure small {
		display:inline;
		margin-left:5px;
	}
}
.mfp-ie7 .mfp-img {
	padding:0;
}
.mfp-ie7 .mfp-bottom-bar {
	width:600px;
	left:50%;
	margin-left:-300px;
	margin-top:5px;
	padding-bottom:5px;
}
.mfp-ie7 .mfp-container {
	padding:0;
}
.mfp-ie7 .mfp-content {
	padding-top: 44px;
}
